import React from 'react';
import theme from '@/styles/theme';
import { Divider, ListItem, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import cdnService from '@/services/cdn/cdnService';
import { Box } from '@mui/system';
import { List, ListItemText } from '@/components/myJob/tabs/styles/tabs.styled';
import JsonViewer from '@/components/manage/job/common/JsonViewer';
import TaskStatus from '@/components/common/statuses/TaskStatus';
import CdnIconName from '@/components/common/CdnIconName';
import { ValidConditionContainer } from '@packages/cp-ui';

const JobTask = ({ task, title }) => {
  const { t } = useTranslation('jobs');
  const vendor = cdnService.getByCdnService(task?.cdn?.service)?.name;
  const ingestData = {
    domain: task?.domain,
    protocol: task?.protocol,
    originPath: task?.originPath
  };
  return (
    <ValidConditionContainer
      condition={task != null}
      fallback={
        <Box
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            textAlign: `center`,
            m: 2,
            py: 6,
            px: 4,
            backgroundColor: theme.palette.secondary[50],
            borderRadius: 2
          }}
        >
          <Typography>
            <Trans i18nKey={'jobs::message.taskDetailInformation'}>
              By selecting the path to detailed information
              <br />
              you can access and review the specific task details
            </Trans>
          </Typography>
        </Box>
      }
    >
      {task != null && (
        <Box sx={{ px: 2, py: 1.5 }}>
          <List>
            <ListItem disablePadding>
              <ListItemText
                sx={{ my: 0.5 }}
                disableTypography
                primary={
                  <Typography className={'MuiListItemText-primary'}>{t('label')}</Typography>
                }
                secondary={title}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                sx={{ my: 0.5 }}
                disableTypography
                primary={<Typography className={'MuiListItemText-primary'}>CDN</Typography>}
                secondary={<CdnIconName service={task.cdn.service} isNameVisible={true} />}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                sx={{ my: 0.5 }}
                disableTypography
                primary={
                  <Typography className={'MuiListItemText-primary'}>{t('status')}</Typography>
                }
                secondary={<TaskStatus status={task.status} />}
              />
            </ListItem>
          </List>
          <Divider sx={{ mb: 3 }} />
          <JsonViewer title={t('requestData')} json={ingestData} />
          <JsonViewer
            title={t('{{vendor}}RequestData', { vendor: vendor })}
            json={task.requestData}
          />
          <JsonViewer
            title={t('{{vendor}}ResponseData', { vendor: vendor })}
            json={task.responseData}
          />
        </Box>
      )}
    </ValidConditionContainer>
  );
};

export default JobTask;
