export const PUBLIC_URL = process.env.PUBLIC_URL;

export const DEFAULT_LANGUAGE = 'en';
export const SUPPORT_LOCALES = [
  { name: 'English', value: 'en-us', label: 'english' },
  { name: '한국어', value: 'ko-kr', label: 'korean' }
];

export const SESSION_STORED_KEY = 'cloudplex.delivery.session';
export const OAUTH_TOKEN_STORED_KEY = 'token';
export const CDN_COLOR = {
  TOTAL: ['#000000', '#000000'],
  AWS_CLOUDFRONT: ['#FD931E', '#EB6400'],
  AKAMAI: ['#44ABFF', '#1381D1'],
  LIMELIGHT: ['#7FC224', '#4E800B'],
  AZURE_EDGIO: ['#1D73E5', '#0E41A0'],
  KT_SOLBOX: ['#DD090A', '#FF296B'],
  ALIBABA_CLOUD_CDN: ['#FC5D02', '#B54200'],
  TENCENT_CLOUD_CDN: ['#0F76BF', '#005695'],
  TENCENT_CLOUD_EDGEONE: ['#0F76BF', '#005695'],
  TENCENT_CSS: ['#0052D9', '#005695'],
  LG_UPLUS: ['#EA18C1', '#EB416D'],
  GOOGLE_CLOUD_CDN: ['#FEBA02', '#E08A00'],
  CLOUDFLARE_CDN: ['#D96400', '#88320C'],
  HYOSUNG_ITX_CDN: ['#133B81', '#133B81'],
  BYTEPLUS_CDN: ['#0766FC', '#0656D4'],
  LG_NIMBUS: ['#EC0B8D', '#C20B73'],
  HUAWEI_CLOUD_CDN: ['#CF0A2C', '#CF0A2C'],
  SK_BROADBAND_CDN: ['#CE0E2D', '#CE0E2D'],
  CLOUDPLEX_MEGAZONE_CDN: ['#009EA0', '#008081'],
  CUSTOM: ['#9FA1AA', '#74767C'],
  ETC_CDN: ['#9FA1AA', '#74767C']
};

export const STORAGE_LABEL_MAPPER = {
  AWS_S3: 'Amazon S3'
};
export const DNS_LABEL_MAPPER = {
  AWS_ROUTE53: 'Amazon Route 53'
};
export const CDN_ICON_MAPPER = {
  AWS_CLOUDFRONT: 'cloudfront',
  AKAMAI: 'akamai',
  LIMELIGHT: 'limelight',
  AZURE_EDGIO: 'azure',
  KT_SOLBOX: 'kt',
  ALIBABA_CLOUD_CDN: 'alibaba',
  TENCENT_CLOUD_CDN: 'tencent',
  TENCENT_CLOUD_EDGEONE: 'tencent',
  TENCENT_CSS: 'tencent',
  LG_UPLUS: 'lguplus',
  GOOGLE_CLOUD_CDN: 'google',
  CLOUDFLARE_CDN: 'cloudflare',
  HYOSUNG_ITX_CDN: 'hyosung',
  BYTEPLUS_CDN: 'byteplus',
  LG_NIMBUS: 'lgNimbus',
  HUAWEI_CLOUD_CDN: 'huawei',
  SK_BROADBAND_CDN: 'sk',
  CLOUDPLEX_MEGAZONE_CDN: 'cloudplex',
  CUSTOM: 'custom'
};

export const CDN_SORTING_ORDER = [
  'AWS_CLOUDFRONT',
  'AKAMAI',
  'AZURE_EDGIO',
  'LIMELIGHT',
  'ALIBABA_CLOUD_CDN',
  'TENCENT_CLOUD_CDN',
  'TENCENT_CLOUD_EDGEONE',
  'TENCENT_CSS',
  'GOOGLE_CLOUD_CDN',
  'CLOUDFLARE_CDN',
  'KT_SOLBOX',
  'LG_UPLUS',
  'HYOSUNG_ITX_CDN',
  'BYTEPLUS_CDN',
  'LG_NIMBUS',
  'HUAWEI_CLOUD_CDN',
  'SK_BROADBAND_CDN',
  'CLOUDPLEX_MEGAZONE_CDN',
  'CUSTOM'
];
export const CDN_PROVIDERS = [
  { label: 'All CDN Provider', value: 'All CDN Provider', color: '' },
  { label: 'Amazon CloudFront', value: 'cloudfront', color: '#ff931e' },
  { label: 'Akamai CDN', value: 'akamai', color: '#44abff' },
  { label: 'Azure CDN', value: 'azure', color: '#1D73E5' },
  { label: 'Edgio CDN', value: 'limelight', color: '#7FC224' },
  { label: 'Alibaba Cloud CDN', value: 'alibaba', color: '#FD5D02' },
  { label: 'Tencent Cloud CDN', value: 'tencent', color: '#0076bf' },
  { label: 'KT CDN', value: 'kt', color: '#dd060a' },
  { label: 'LG U+ CDN', value: 'lguplus', color: '#a50034' },
  { label: 'Google Cloud CDN', value: 'google', color: '#FEBA02' },
  { label: 'Cloudflare CDN', value: 'cloudflare', color: '#d96400' },
  { label: 'Hyosung ITX CDN', value: 'hyosung', color: '#133B81' },
  { label: 'BytePlus CDN', value: 'byteplus', color: '#0766FC' },
  { label: 'LG Nimbus CDN', value: 'lgNimbus', color: '#EC0B8D' },
  { label: 'Huawei Cloud CDN', value: 'huawei', color: '#CF0A2C' },
  { label: 'SK Broadband CDN', value: 'sk', color: '#CE0E2D' },
  { label: 'Megazone CDN', value: 'cloudplex', color: '#009EA0' },
  { label: 'Custom', value: 'custom', color: '#9fa1aa' }
];

export const LIMIT_BYTES = {
  name: 255,
  title: 255,
  description: 2000,
  attribution: 200,
  tag: 150,
  comment: 255,
  descriptionChannel: 2000,
  roleTitle: 50,
  roleDescription: 512,
  identifier: 30,
  dnsActualDomain: 128,
  dnsHostedZoneName: 128,
  endpointDomain: 255
};

export const CDN_METRICS_INTERVAL = {
  minute5: 'FIVE_MINUTE',
  hour1: 'ONE_HOUR',
  day1: 'ONE_DAY'
};
